<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="9" class="d-flex justify-center align-center ">
          <v-img
            max-width="30%"
            src="../../assets/sobre_territorio_camargos_18.png"
          ></v-img>
          <div class="d-flex flex-column justify-space-between poem">
            <p>
              <span>“TEM TANTAS BELEZAS, TANTAS!</span>
              <span>O CRUZEIRO!</span>
              <span>A IGREJINHA, LÁ NO ALTO DA COLINA,</span>
              <span>DA NOSSA MÃE E RAINHA.”</span>
            </p>

            <p style="font-size: 0.6em; margin-bottom: 0;">
              Maria de Lurdes André Pereira, Dona Cota.
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card
            title="Igreja Matriz de Nossa Senhora da Conceição"
            titleColor="#000"
            borderColor="#e06919"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                A fundação da paróquia ocorreu por volta dos anos de 1690. A
                construção da capela teria sido iniciada por volta de 1707, e
                concluída em meados do século XVIII, sendo uma das mais antigas
                de Minas Gerais. O templo foi erguido no alto de uma colina, e,
                em ponto mais baixo do terreno, no encontro com a Rua do
                Cruzeiro, foi implantado um cruzeiro de pedra. Uma escadaria
                também de pedra liga o cruzeiro ao adro da igreja, contornado
                por uma mureta de pedras. A composição constitui uma
                singularidade quanto à implantação urbana de Minas Gerais do
                século XVIII.
              </p>
              <p>
                Apresenta planta retangular, desenvolvida em dois pavimentos,
                sendo o primeiro correspondente à nave, capela‐mor, átrio,
                corredores laterais e sacristia. O segundo pavimento é
                conformado pelas tribunas e pelo coro. O frontispício confere
                aspecto robusto, com torres baixas e frontão triangular simples,
                arrematado por telhas. Ao lado da portada larga encimada por
                pequena cimalha e ornamentos, em altura superior, existem duas
                sacadas. A capela‐mor, em adobe, talvez seja um elemento
                remanescente da primitiva capela.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_19.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Vista da fachada frontal e da escadaria que conduz à Matriz de Nossa
            Senhora da Conceição. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_20.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Vista da fachada frontal e do adro da Matriz de Nossa Senhora da
            Conceição Foto: Flora Passos, 2018.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_21.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Vista da fachada lateral esquerda (observador posicionado de costas
            para a igreja) e do cemitério da Matriz de Nossa Senhora da
            Conceição. Foto: Flora Passos, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A parte da nave aparenta ter sido construída em pedra, com
                nichos para capelas laterais, e existem indicações que também
                foi usado o adobe nas porções superiores dessas paredes. O
                retábulo do altar-mor ocupa todo o fundo do presbitério;
                apresenta farta decoração em talha, com muitas esculturas,
                elementos zoomorfos (forma de animais) e fitomorfos (forma de
                plantas) e dossel estilizado, da primeira fase do barroco. Os
                altares colaterais também são abundantemente trabalhados em
                talha e esculturas, e o arco-cruzeiro é bem simples. Existem
                sinais de que o forro atual arqueado foi feito em substituição a
                um forro original em forma de gamela, com painéis contendo
                pintura artística, ao modo das que restam acima do arco
                cruzeiro.
              </p>
              <p>
                A igreja passou por reformas no início do século XX, e foi
                tombada em 1949 pelo IPHAN. As festas de Nossa Senhora da
                Conceição e de Santa Cruz ainda são vigentes e podem ser
                encontradas no
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_religiosidade_calendario' }"
                  >calendário litúrgico</router-link
                >
                das comunidades atingidas.
              </p>

              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_22.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Vista a partir da lateral direita (observador posicionado de costas
            para a igreja) da torre da Matriz de Nossa Senhora da Conceição.
            Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_23.png"
          />
          <p style="font-size: 0.6em; margin-bottom: 0; text-align: center">
            Vista do altar-mor da Igreja Matriz de Nossa Senhora da Conceição.
            Foto: Flora Passos, 2016.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.poem p span {
  display: block;
  font-size: 0.8em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  margin-top: 2em;
}

.inside__card p {
  font-size: 0.6em;
}
</style>
